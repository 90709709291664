import { _WEB_VERSION } from './constant';
// export const _BASE_URL = 'https://localhost:5001'; // Localhost
export const _BASE_URL = 'http://stage-supplier-api.jw-diamonds.com'; // Stage
// export const _BASE_URL = 'https://supplier-api.jw-diamonds.com'; // Production

const Routes = {
    _ACCOUNT_LOGIN: '/account/login',
    _USER_REGISTER: '/user/register',
    _FORGOT_PASSWORD: '/user/forgot-password',
    _CHANGE_PASSWORD: '/user/change-password',
    _ACCOUNT_VERIFY: '/account/verify',
    _ACCOUNT_LOGOUT: '/account/logout',
    _ACCOUNT_IS_LOGGED_IN: '/account/is-logged-in',
    _COMPANY_PROFILE: '/user/company-profile',
    _USER: '/user',
    _INVENTORY_DIAMONDS: '/inventory/diamonds',
    _HOLD_DIAMONDS: '/inventory/diamonds/hold',
    _INVENTORY_HOLD_DIAMONDS: '/inventory/hold-diamonds',
    _ORDER_DIAMONDS: 'inventory/diamonds/buy',
    _INVENTORY_ORDER: '/inventory/order',
    _INVENTORY_GENERATE_INVOICE: '/inventory/invoice/request',
    _INVENTORY_INVOICE: "/inventory/invoice",
    _DASHBOARD_DATA: "/report/dashboard-data"
};

export default Routes;
